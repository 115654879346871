import {
  DB_RESET_VALIDATION_LOG,
  DB_FETCH_VALIDATION_LOG_LIST_START,
  DB_FETCH_VALIDATION_LOG_LIST_SUCCESS,
  DB_FETCH_VALIDATION_LOG_LIST_FAILED,
  // DB_FETCH_VALIDATION_LOG_START,
  // DB_FETCH_VALIDATION_LOG_SUCCESS,
  // DB_FETCH_VALIDATION_LOG_FAILED,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  validationLogs: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoadingList: false,
  isLoadingDetail: false,
  validationLog: {},
};

const validationReducer = (state = initialState, action) => {
  switch (action.type) {
    case DB_RESET_VALIDATION_LOG:
      return updatedObject(state, {
        isLoadingDetail: false,
        validationLogs: [],
        validationLog: {},
      });

    case DB_FETCH_VALIDATION_LOG_LIST_START:
      return updatedObject(state, {
        isLoadingList: true,
        paging: initialState.paging,
        validationLogs: initialState.validationLogs,
      });

    case DB_FETCH_VALIDATION_LOG_LIST_SUCCESS:
      const validationLogs = { ...action.payload };
      const newPaging = {
        activePage: validationLogs.number + 1,
        itemsCountPerPage: validationLogs.size,
        totalItemsCount: validationLogs.totalElements,
        dataSize: validationLogs.content ? validationLogs.content.length : 0,
      };
      return updatedObject(state, {
        validationLogs: action.payload.content || [],
        paging: newPaging,
        isLoadingList: false,
      });

    case DB_FETCH_VALIDATION_LOG_LIST_FAILED:
      return updatedObject(state, {
        isLoadingList: false,
        paging: initialState.paging,
        validationLogs: initialState.validationLogs,
      });

    default:
      return state;
  }
};
export default validationReducer;
