import React, { Suspense, lazy } from "react";
import { withRouter, Switch, Redirect } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

/* loader component for Suspense*/
import PageLoader from "./components/Common/PageLoader";

import Base from "./components/Layout/Base";
import BasePage from "./components/Layout/BasePage";
// import BaseHorizontal from './components/Layout/BaseHorizontal';

import { PrivateRoute } from "./components/Common/PrivateRoute";
import { NonAuthRoute } from "./components/Common/NonAuthRoute";

/* Used to render a lazy component with react-router */
const waitFor = (Tag) => (props) => <Tag {...props} />;

// const PartyProfile = lazy(() => import("./screens/Party/Profile"));
// const PartyProfileDetail = lazy(() => import("./screens/Party/ProfileDetail"));
// const PartyRole = lazy(() => import("./screens/Party/Role"));
// const PartyRoleDetail = lazy(() => import("./screens/Party/RoleDetail"));
// const PartyIndividual = lazy(() => import("./screens/Party/Individual"));
// const PartyIndividualDetail = lazy(() =>
//   import("./screens/Party/IndividualDetail")
// );
// const PartyOrganization = lazy(() => import("./screens/Party/Organization"));
// const PartyOrganizationDetail = lazy(() =>
//   import("./screens/Party/OrganizationDetail")
// );

// const ProductProfile = lazy(() => import("./screens/Product/Profile"));
// const ProductProfileDetail = lazy(() =>
//   import("./screens/Product/ProfileDetail")
// );
// const ProductCategory = lazy(() => import("./screens/Product/Category"));
// const ProductCategoryDetail = lazy(() =>
//   import("./screens/Product/CategoryDetail")
// );
// const ProductManager = lazy(() => import("./screens/Product/Manager"));
// const ProductManagerDetail = lazy(() =>
//   import("./screens/Product/ManagerDetail")
// );

/*********************---WATCH LIST FILTERING--START-************************************* */
/**Watch List Filtering - Dashboard */
const WlfDashboard = lazy(() =>
  import("./screens/WatchList/Dashboard/Dashboard")
);

/** Monitoring*/
const Monitoring = lazy(() => import("./modules/wlf/monitoring/pages/list"));
const MonitoringDetail = lazy(() =>
  import("./modules/wlf/monitoring/pages/detail")
);

/** Entity search*/
const EntitySearch = lazy(() => import("./modules/wlf/entity/pages/list"));
const EntitySearchDetail = lazy(() =>
  import("./modules/wlf/entity/pages/detail")
);

/**Vessel/Aircraft search */
const VesselSearch = lazy(() => import("./modules/wlf/vessel/pages/list"));
const VesselSearchDetail = lazy(() =>
  import("./modules/wlf/vessel/pages/detail")
);

/*API & Channel manager*/
const ApiAndChannelManager = lazy(() =>
  import("./modules/wlf/channel/pages/list")
);
const ApiAndChannelManagerDetail = lazy(() =>
  import("./modules/wlf/channel/pages/detail")
);

/*WL update*/
const ListUpdate = lazy(() =>
  import("./modules/wlf/listUpdate/pages/wlUpdateList")
);
const ListUpdateDetail = lazy(() =>
  import("./modules/wlf/listUpdate/pages/wlUpdateDetail/WLUpdateDetail")
);

/*Format Manager*/
const FormatManager = lazy(() => import("./modules/wlf/check/pages/checkList"));
const FormatManagerDetail = lazy(() =>
  import("./modules/wlf/check/pages/checkDetail/CheckDetail")
);

/*WL manager */
const ListManager = lazy(() =>
  import("./modules/wlf/manager/pages/managerList")
);
const ListManagerDetail = lazy(() =>
  import("./modules/wlf/manager/pages/managerDetail/ManagerDetail")
);

/*Sanctions program */
const SanctionsProgram = lazy(() =>
  import("./modules/wlf/program/pages/programList")
);
const SanctionsProgramDetail = lazy(() =>
  import("./modules/wlf/program/pages/programDetail/ProgramDetail")
);

const WhiteList = lazy(() =>
  import("./modules/wlf/whiteList/pages/whiteListList")
);

const WhiteListDetail = lazy(() =>
  import("./modules/wlf/whiteList/pages/whiteListDetail/WhitelistDetail")
);

/*Stop-Skip Word */
const StopSkipWordList = lazy(() =>
  import("./modules/wlf/stopWord/pages/list")
);

const StopSkipWordDetail = lazy(() =>
  import("./modules/wlf/stopWord/pages/detail")
);

// const CaseManager = lazy(() => import('./screens/WatchList/CaseManager'));
// const CaseManagerDetail = lazy(() => import('./screens/WatchList/CaseManagerDetail'));
// const ListSetup = lazy(() => import('./screens/WatchList/ListSetup'));
// const ListSetupDetail = lazy(() => import('./screens/WatchList/ListSetupDetail'));

/*Module setting */
// const ModuleSetting = lazy(() => import('./screens/WatchList/ModuleSetting'));
/*********************---WATCH LIST FILTERING--END-************************************* */

/*********************---KNOW YOUR PARTY--START-************************************* */
/*KYP Dashboard */
const KypDashboard = lazy(() =>
  import("./screens/KnowYourParty/Dashboard/Dashboard")
);

/*KYP ALert*/
const CaseAlert = lazy(() =>
  import("./modules/ce/alert/pages/alertList/index")
);
const CaseAlertDetail = lazy(() =>
  import("./modules/ce/alert/pages/alertDetail/AlertDetail")
);

/*KYP Profiles */
// const KypProfile = lazy(() => import("./screens/KnowYourParty/Profile"));
const KypProfile = lazy(() =>
  import("./modules/kyp/profile/pages/profileList")
);
const KypProfileDetail = lazy(() =>
  import("./modules/kyp/profile/pages/profileDetail/ProfileDetail")
);

// /*KYP Acceptances */
// const KypAcceptancesList = lazy(() =>
//   import("./modules/kyp/acceptanceList/pages/acceptanceList/AcceptanceListPage")
// );

// const KypAcceptanceDetail = lazy(() =>
//   import("./modules/kyp/acceptanceList/pages/acceptanceDetail/AcceptanceDetail")
// );

// /*KYP AcceptanceSegments */
// const KypAcceptanceSegments = lazy(() =>
//   import("./modules/kyp/acceptanceSegment/pages/acceptanceSegmentList/AcceptanceSegmentListPage")
// );
// const KypAcceptanceSegmentsDetail = lazy(() =>
//   import("./modules/kyp/acceptanceSegment/pages/acceptanceSetupDetail/AcceptanceSetupDetail")
// );

/*KYP Risks */
const KypRiskFactor = lazy(() =>
  import("./modules/kyp/riskFactor/pages/riskFactorList/RiskFactorListPage")
);
const KypRiskFactorDetail = lazy(() =>
  import("./modules/kyp/riskFactor/pages/riskFactorDetail/RiskFactorDetail")
);

/*KYP RiskSegments */
const KypRiskScoringDefine = lazy(() =>
  import(
    "./modules/kyp/riskScoreDefine/pages/riskScoreDefineList/RiskScoreDefineListPage"
  )
);
const KypRiskScoreDefineDetail = lazy(() =>
  import(
    "./modules/kyp/riskScoreDefine/pages/riskScoreDefineDetail/RiskScoreDefineDetail"
  )
);

/*KYP Roles */
const KypRole = lazy(() => import("./modules/kyp/role/pages/roleList"));
const KypRoleDetail = lazy(() =>
  import("./modules/kyp/role/pages/roleDetail/RoleDetail")
);

// const KypRiskAssessment = lazy(() => import('./screens/KnowYourParty/RiskAssessment'));
// const KypRiskAssessmentDetail = lazy(() => import('./screens/KnowYourParty/RiskAssessmentDetail'));

/*KYP Individuals */
const KypIndividual = lazy(() =>
  import("./modules/kyp/individual/pages/individualList")
);
const KypIndividualDetail = lazy(() =>
  import("./modules/kyp/individual/pages/individualDetailNew")
);

/*KYP Organizations */
const KypOrganization = lazy(() =>
  import("./modules/kyp/organization/pages/organizationList")
);
const KypOrganizationDetail = lazy(() =>
  import("./modules/kyp/organization/pages/organizationDetailNew")
);

/*AML Policy */
const AMLPolicy = lazy(() =>
  import("./modules/kyp/policy/pages/moduleDetail/PolicySetting")
);

/*KYP Module Setting */
const KYPModuleSetting = lazy(() =>
  import("./modules/kyp/setting/pages/moduleDetail/KYPModuleSetting")
);

/*KYP Reassessment */
const KYPReassessment = lazy(() =>
  import("./modules/kyp/reassessment/pages/list")
);

// const KypModuleSetting = lazy(() => import('./screens/KnowYourParty/ModuleSetting'));
/*********************---KNOW YOUR PARTY--END-************************************* */

/*********************---TRANSACTION MONITORING--START-************************************* */
/**Dashboard */
const TmsDashboard = lazy(() => import("./screens/TMS/Dashboard/Dashboard"));

/**Scenario Setup */
const ScenarioSetup = lazy(() =>
  import("./modules/tms/scenarioSetup/pages/scenarioSetupList")
);
const ScenarioSetupDetail = lazy(() =>
  import("./modules/tms/scenarioSetup/pages/scenarioSetupDetail")
);

/**Scenario Setup */
const CountryAnalysis = lazy(() =>
  import("./modules/tms/countryAnalysis/pages/listPage")
);
const CountryAnalysisDetail = lazy(() =>
  import("./modules/tms/countryAnalysis/pages/detailPage")
);

const ModuleSetting = lazy(() =>
  import("./modules/tms/moduleSetting/pages/moduleDetail/ModuleDetail")
);

/**TMS Alert */
const AlertMonitoring = lazy(() =>
  import("./modules/tms/alertMonitoring/pages/listPage")
);
const AlertMonitoringDetail = lazy(() =>
  import("./modules/tms/alertMonitoring/pages/detailPage")
);
// const tmsAlertDetail = lazy(() => import("./screens/TMS/AlertDetail"));

/**TMS Scenario Analysis */
const ScenarioAnalysis = lazy(() =>
  import("./modules/tms/scenarioAnalysis/pages/listPage")
);
const ScenarioAnalysisDetail = lazy(() =>
  import("./modules/tms/scenarioAnalysis/pages/detailPage")
);
/**TMS Alert 2 */
// const tmsAlert2 = lazy(() => import("./screens/TMS/Alert2"));
// const tmsAlertDetail2 = lazy(() => import("./screens/TMS/AlertDetail2"));

/**TMS Investigation */
const tmsInvestigation = lazy(() =>
  import("./modules/tms/investigation/pages/listPage")
);
const tmsInvestigationDetail = lazy(() =>
  import("./modules/tms/investigation/pages/detailPage")
);

/**Link Analysis */
// const linkAnalysis = lazy(() => import("./screens/TMS/LinkAnalysis"));

const CustomerAnalysis = lazy(() =>
  import("./modules/tms/customerAnalysis/pages/listPage")
);
const CustomerAnalysisDetail = lazy(() =>
  import("./modules/tms/customerAnalysis/pages/detailPage")
);

const AmStaffAnalysis = lazy(() =>
  import("./modules/tms/amStaffAnalysis/pages/listPage")
);
const AmStaffAnalysisDetail = lazy(() =>
  import("./modules/tms/amStaffAnalysis/pages/detailPage")
);

const LinkAnalysis = lazy(() =>
  import("./modules/tms/linkAnalysis/pages/blankPage")
);

const STRManager = lazy(() =>
  import("./modules/tms/strManagement/pages/strList")
);
const STRManagerDetail = lazy(() =>
  import("./modules/tms/strManagement/pages/strDetail")
);
/*********************---TRANSACTION MONITORING--END-************************************* */

/*********************---DATA VISUALIZATION-START-************************************* */

const WorkspaceMainPage = lazy(() =>
  import("./modules/dataVisualization/Workspaces/pages/WorkspaceList")
);

/**VD Customer Searching */
const CustomerSearchingPage = lazy(() =>
  import("./modules/dataVisualization/customerSearching/pages/list")
);

const CustemerSearchingMainPage = lazy(() =>
  import("./modules/dataVisualization/customerSearching/pages/main")
);
/**VD Group Searching */
const GrouprSearchingPage = lazy(() =>
  import("./modules/dataVisualization/groupSearching/pages/list")
);

const GroupSearchingMainPage = lazy(() =>
  import("./modules/dataVisualization/groupSearching/pages/main")
);
/*********************---GRAPH BROWSER-START-************************************* */
/**VD Customer Searching */
const EmbededNeo4jBrowser = lazy(() =>
  import("./modules/dataVisualization/graphBrowser/pages")
);

const InvestigationList = lazy(() =>
  import("./modules/dataVisualization/Investigation/pages/InvestigationList")
);

const InvestigationDetail = lazy(() =>
  import(
    "./modules/dataVisualization/Investigation/pages/InvestigationDetail/InvestigationDetail"
  )
);

/*********************---SYSTEM ADMIN--START-************************************* */
/**MS CompanyInformations */

const MSDashboard = lazy(() =>
  import("./modules/ms/dashboard/pages/MSDashboard")
);

const MsCompanyInformations = lazy(() =>
  import("./modules/ms/company/pages/companyDetail/CompanyDetail")
);
/**MS SystemCodes */
// const MsSystemCodes = lazy(() => import("./screens/SystemAdmin/SystemCode"));
// const MsSystemCodestail = lazy(() =>
//   import("./screens/SystemAdmin/SystemCodeDetail")
// );

/**User Role */
const MsUserRole = lazy(() => import("./modules/ms/role/pages/roleList"));
const MsUserRoleDetail = lazy(() =>
  import("./modules/ms/role/pages/roleDetail/UserRoleDetail")
);

/**MS  Users*/
const MsUserManager = lazy(() => import("./modules/ms/user/pages/userList"));
const MsUserManagerDetail = lazy(() =>
  import("./modules/ms/user/pages/userDetail/UserDetail")
);

/**MS  SystemCodes*/
const MsSystemCode = lazy(() =>
  import("./modules/ms/systemCode/pages/systemCodeList")
);
const MsSystemCodeDetail = lazy(() =>
  import("./modules/ms/systemCode/pages/systemCodeDetail/SystemCodeDetail")
);

/**MS  Template*/
const MsTemplate = lazy(() =>
  import("./modules/ms/notificationTemplate/pages/templateList")
);

const MsTemplateDetail = lazy(() =>
  import(
    "./modules/ms/notificationTemplate/pages/templateDetail/TemplateDetail"
  )
);

/* Dual Control */
const MsDualControls = lazy(() =>
  import("./modules/ms/dualControl/pages/list")
);
const MsDualControlsDetail = lazy(() =>
  import("./modules/ms/dualControl/pages/detail/DualControlDetail")
);

/* Audit Log */
const AuditLogsDetail = lazy(() =>
  import("./modules/ms/auditLog/pages/auditLogDetail/AuditLogDetail")
);

/* Export Data Setting */
const ExportDataSetting = lazy(() =>
  import("./modules/ms/exportSetting/pages/exportList")
);

/* Batch Job Managemet */
const BatchJobManagement = lazy(() =>
  import("./modules/ms/batchJob/pages/batchJobList")
);
const BatchJobDetail = lazy(() =>
  import("./modules/ms/batchJob/pages/batchJobDetail/BatchJobDetail")
);

/* Report */
const STReport = lazy(() => import("./modules/report/STReport/pages/index"));
const AccessDVReport = lazy(() =>
  import(
    "./modules/report/dataAnalytics/AccessReportDV/pages/viewReportPage/index"
  )
);
const AccessReport = lazy(() =>
  import(
    "./modules/report/dataAnalytics/AccessReport/pages/viewReportPage/index"
  )
);
const FIReport = lazy(() =>
  import("./modules/report/creditInstitutionReport/pages/list")
);

const CustomerDetailReport = lazy(() =>
  import("./modules/report/customerDetail/viewReportPage")
);

const CustomerTransactionReport = lazy(() =>
  import("./modules/report/customerTransaction/viewReportPage")
);
const SummaryCustomerReport = lazy(() =>
  import("./modules/report/exportCustomerTransaction/viewReportPage")
);

const SummaryCustomerTransactionReport = lazy(() =>
  import("./modules/report/summaryCustomerTransaction/viewReportPage")
);

const SummaryTopCustomerReport = lazy(() =>
  import("./modules/report/summaryTop100/viewReportPage")
);

const StatisticCustomerTransactionReport = lazy(() =>
  import("./modules/report/statisticCustomerTransaction/viewReportPage")
);

const BankReportMissing = lazy(() =>
  import("./modules/report/bankReportMissing/pages/mainPage/index")
);

const SummaryByAlertReport = lazy(() =>
  import("./modules/report/alert/summaryByAlert/viewReportPage")
);

const SummaryByCustomerReport = lazy(() =>
  import("./modules/report/alert/summaryByCustomer/viewReportPage")
);
/*********************---CASE MANAGEMENT--START-************************************* */
/**Dashboard */
const CEDashboard = lazy(() => import("./modules/ce/dashboard"));
/**Case Process */
const CECaseProcess = lazy(() =>
  import("./modules/ce/caseProcess/pages/processList")
);
const CECaseProcessDetail = lazy(() =>
  import("./modules/ce/caseProcess/pages/processDetail/CaseProcessDetail")
);

const CaseManagement = lazy(() =>
  import("./modules/ce/caseManagement/pages/caseList/index")
);
const CaseManagementDetail = lazy(() =>
  import("./modules/ce/caseManagement/pages/caseDetail/CaseManagementDetail")
);

const KYPAlert = lazy(() =>
  import("./modules/kyp/alert/pages/alertList/index")
);
const KYPAlertDetail = lazy(() => import("./modules/kyp/alert/pages/detail"));

// WorkFlow
const CaseWorkflow = lazy(() =>
  import("./modules/ce/workflow/pages/workflowList/index")
);

const CaseWorkflowDetail = lazy(() =>
  import("./modules/ce/workflow/pages/workflowDetail/WorkflowDetail")
);

//Prority
const CasePriority = lazy(() =>
  import("./modules/ce/casePiority/pages/casePiorityList/index")
);

const CasePriorityDetail = lazy(() =>
  import("./modules/ce/casePiority/pages/casePiorityDetail/CasePriorityDetail")
);

// const CaseProcessDetail = lazy(() =>
//   import("./modules/cm/caseProcess/pages/caseDetail/CaseDetail")
/*********************---CASE MANAGEMENT--END-************************************* */

/*********************---PERSONALITY--START-************************************* */
/**Dashboard */
const DBDashboard = lazy(() => import("./modules/personality/Dashboard/pages"));
// const PersonalProfile = lazy(() =>
//   import("./modules/personality/Profile/pages")
// );

const PersonalSetting = lazy(() =>
  import("./modules/personality/Settings/pages")
);

const UserDashboard = lazy(() =>
  import("./modules/personality/UserDashboard/pages")
);
const PersonalNotification = lazy(() =>
  import("./modules/personality/Notification/pages/notificationList")
);
const PersonalComment = lazy(() =>
  import("./modules/personality/Comment/pages/commentList")
);
// const PersonalProfile = lazy(() =>
//   import("./modules/personality/Profile/pages")
// );

const PersonalFavorite = lazy(() =>
  import("./modules/personality/Favorite/pages/dasdboard")
);

const Delegation = lazy(() =>
  import("./modules/personality/Delegation/pages/DelegationList")
);

const DelegationDetail = lazy(() =>
  import(
    "./modules/personality/Delegation/pages/DelegationDetail/DelegationDetail"
  )
);

/*********************---PERSONALITY--END-************************************* */

/*********************---REPORT--START-************************************* */
/**Dashboard */
// const STReport = lazy(() => import("./modules/report/test/pages"));

/*********************---REPORT--END-************************************* */
/*********************---ALERT_MANAGEMENT--START-************************************* */

/*ALERT Dashboard */
const AlertDashboard = lazy(() => import("./modules/alert/dashboard"));

/*ALERT alertResult */
const AlertResults = lazy(() =>
  import("./modules/alert/alertResult/pages/alertResultList")
);

const AlertResultDetail = lazy(() =>
  import(
    "./modules/alert/alertResult/pages/alertResultDetail/AlertResultDetail"
  )
);

/*ALERT alertResultCustomer */
const AlertResultsCustomer = lazy(() =>
  import("./modules/alert/alertResultCustomer/pages/alertResultCustomerList")
);

const AlertResultCustomerDetail = lazy(() =>
  import(
    "./modules/alert/alertResultCustomer/pages/alertResultCustomerDetail/AlertResultCustomerDetail"
  )
);

const AlertNCCTTaxHaven = lazy(() =>
  import("./modules/alert/ncctAndTaxHaven/pages/Listing")
);

/**Alert Scenario Setup*/
const AlertScenarioSetup = lazy(() =>
  import("./modules/alert/scenarioSetup/pages/scenarioSetupList")
);
const AlertScenarioSetupDetail = lazy(() =>
  import(
    "./modules/alert/scenarioSetup/pages/scenarioSetupDetail/ScenarioSetupDetail"
  )
);

/**Alert WhiteList*/
const AlertWhiteList = lazy(() =>
  import("./modules/alert/whiteListManagement/pages/whiteListList")
);

const AlertWhiteListDetail = lazy(() =>
  import(
    "./modules/alert/whiteListManagement/pages/whiteListDetail/WhitelistDetail"
  )
);

// Alert-STR Manual
const AlertSTRManual = lazy(() =>
  import("./modules/alert/strManual/pages/strList")
);
const AlertSTRManualDetail = lazy(() =>
  import("./modules/alert/strManual/pages/strDetail")
);

/**Alert Request FI*/
const AlertRequestFI = lazy(() =>
  import("./modules/alert/requestFI/pages/requestFIList")
);

const AlertRequestFIDetail = lazy(() =>
  import("./modules/alert/requestFI/pages/requestFIDetail/RequestFIDetail")
);

const DelegateTask = lazy(() =>
  import("./modules/alert/delegateTask/taskList")
);
const AlertProgress = lazy(() => import("./modules/alert/alertProgess/pages"));
/**Alert Assistant*/
const AlertAssistant = lazy(() =>
  import("./modules/alert/assistant/pages/assistantList")
);

/*********************---ALERT_MANAGEMENT--END-************************************* */

const FraudDetection = lazy(() =>
  import("./modules/fraudDetection/flinkDemo/pages")
);
const CustomerInfo = lazy(() =>
  import("./modules/dataValidation/customerInfo/page/custInfoList")
);

const ValidationLog = lazy(() =>
  import("./modules/dataValidation/validationLog/page/validationLogList")
);

const Login = lazy(() => import("./components/Pages/Login"));
const PageCallback = lazy(() => import("./components/Pages/PageCallback"));
const NotFound = lazy(() => import("./components/Pages/NotFound"));

// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const listofPages = [
  "/login",
  "/register",
  "/recover",
  "/lock",
  // "/checking",
  "/authorized",
  // '/notfound',
  "/error500",
  "/maintenance",
];

const Routes = ({ location }) => {
  const currentKey = location.pathname.split("/")[1] || "/";
  const timeout = { enter: 500, exit: 500 };

  // Animations supported
  //      'rag-fadeIn'
  //      'rag-fadeInRight'
  //      'rag-fadeInLeft'

  const animationName = "rag-fadeIn";

  if (listofPages.indexOf(location.pathname) > -1) {
    return (
      // Page Layout component wrapper
      <BasePage>
        <Suspense fallback={<PageLoader />}>
          <Switch location={location}>
            <NonAuthRoute path="/login" component={waitFor(Login)} />
            {/* <NonAuthRoute path="/checking" component={waitFor(PageCallback)} /> */}
            <NonAuthRoute
              path="/authorized"
              component={waitFor(PageCallback)}
            />
            {/* <Route path="/register" component={waitFor(Register)}/> */}
            {/* <Route path="/recover" component={waitFor(Recover)}/> */}
            {/* <Route path="/lock" component={waitFor(Lock)}/> */}
            {/* <Route path="/notfound" component={waitFor(NotFound)}/> */}
            {/* <Route path="/error500" component={waitFor(Error500)}/> */}
            {/* <Route path="/maintenance" component={waitFor(Maintenance)}/> */}
          </Switch>
        </Suspense>
      </BasePage>
    );
  } else {
    return (
      // Layout component wrapper
      // Use <BaseHorizontal> to change layout
      <Base>
        <TransitionGroup>
          <CSSTransition
            key={currentKey}
            timeout={timeout}
            classNames={animationName}
            exit={false}
          >
            <div>
              <Suspense fallback={<PageLoader />}>
                <Switch location={location}>
                  {/* START PERSONALITY */}
                  <PrivateRoute
                    path="/me/Dashboard"
                    exact
                    component={waitFor(UserDashboard)}
                    objectId="Dashboards.UserDashboard"
                  />
                  <PrivateRoute
                    path="/me/OverallDashboard"
                    exact
                    component={waitFor(DBDashboard)}
                    objectId="Dashboards.OverallDashboard"
                  />

                  <PrivateRoute
                    path="/me/AlertDashboard"
                    exact
                    component={waitFor(AlertDashboard)}
                    objectId="Dashboards.ATDashboard"
                  />

                  <PrivateRoute
                    path="/me/CaseDashboard"
                    exact
                    component={waitFor(CEDashboard)}
                    objectId="Dashboards.CEDashboard"
                  />

                  <PrivateRoute
                    path="/me/MSDashboard"
                    exact
                    component={waitFor(MSDashboard)}
                    objectId="Dashboards.MSDashboard"
                  />

                  {/* <PrivateRoute
                    path="/me/Profile"
                    exact
                    component={waitFor(PersonalProfile)}
                    objectId="Personality.Profile"
                  /> */}
                  <PrivateRoute
                    path="/me/Notification"
                    exact
                    component={waitFor(PersonalNotification)}
                    objectId="Personality.Notification"
                  />
                  <PrivateRoute
                    path="/me/Comment"
                    exact
                    component={waitFor(PersonalComment)}
                    objectId="Personality.Comment"
                  />
                  <PrivateRoute
                    path="/me/Setting"
                    exact
                    component={waitFor(PersonalSetting)}
                    objectId="Personality.Setting"
                  />
                  <PrivateRoute
                    path="/me/favorite"
                    exact
                    component={waitFor(PersonalFavorite)}
                    objectId="Personality.Favorite"
                  />
                  <PrivateRoute
                    path="/me/Delegation"
                    exact
                    component={waitFor(Delegation)}
                    objectId="Personality.Delegation"
                  />
                  <PrivateRoute
                    path="/me/Delegation/:id"
                    exact
                    component={waitFor(DelegationDetail)}
                    objectId="Personality.Delegation.Details"
                  />
                  {/* END PERSONALITY */}
                  {/* START WATCHLIST FILTERING */}
                  <PrivateRoute
                    path="/wlf/Dashboard"
                    component={waitFor(WlfDashboard)}
                    objectId="WLF.WLFDashboard"
                  />
                  <PrivateRoute
                    path="/wlf/Monitoring"
                    exact
                    component={waitFor(Monitoring)}
                    objectId="WLF.Monitoring"
                  />
                  <PrivateRoute
                    path="/wlf/Monitoring/:id"
                    exact
                    component={waitFor(MonitoringDetail)}
                    objectId="WLF.Monitoring.Details"
                  />
                  <PrivateRoute
                    path="/wlf/Search"
                    exact
                    component={waitFor(EntitySearch)}
                    objectId="WLF.Search"
                  />
                  <PrivateRoute
                    path="/wlf/Search/:id"
                    exact
                    component={waitFor(EntitySearchDetail)}
                    objectId="WLF.Search.Details"
                  />
                  <PrivateRoute
                    path="/wlf/VesselAircraftSearch"
                    exact
                    component={waitFor(VesselSearch)}
                    objectId="WLF.VesselAircraft"
                  />
                  <PrivateRoute
                    path="/wlf/VesselAircraftSearch/:id"
                    exact
                    component={waitFor(VesselSearchDetail)}
                    objectId="WLF.VesselAircraft.Details"
                  />
                  <PrivateRoute
                    path="/wlf/Channels"
                    exact
                    component={waitFor(ApiAndChannelManager)}
                    objectId="WLF.Channel"
                  />
                  <PrivateRoute
                    path="/wlf/Channels/:id"
                    exact
                    component={waitFor(ApiAndChannelManagerDetail)}
                    objectId="WLF.Channel.Details"
                  />
                  <PrivateRoute
                    path="/wlf/ListUpdates"
                    exact
                    component={waitFor(ListUpdate)}
                    objectId="WLF.ListUpdate"
                  />
                  <PrivateRoute
                    path="/wlf/ListUpdates/:id"
                    exact
                    component={waitFor(ListUpdateDetail)}
                    objectId="WLF.ListUpdate.Details"
                  />
                  <PrivateRoute
                    path="/wlf/Checks"
                    exact
                    component={waitFor(FormatManager)}
                    objectId="WLF.Check"
                  />
                  <PrivateRoute
                    path="/wlf/Checks/:id"
                    exact
                    component={waitFor(FormatManagerDetail)}
                    objectId="WLF.Check.Details"
                  />
                  <PrivateRoute
                    path="/wlf/Lists"
                    exact
                    component={waitFor(ListManager)}
                    objectId="WLF.List"
                  />
                  <PrivateRoute
                    path="/wlf/Lists/:id"
                    exact
                    component={waitFor(ListManagerDetail)}
                    objectId="WLF.List.Details"
                  />
                  {/* <PrivateRoute path="/wlf/list-setup" exact component={waitFor(ListSetup)}/>
                                <PrivateRoute path="/wlf/list-setup/:id" exact component={waitFor(ListSetupDetail)}/> */}
                  {/* <PrivateRoute path="/wlf/case-manager" exact component={waitFor(CaseManager)}/>
                                <PrivateRoute path="/wlf/case-manager/:id" exact component={waitFor(CaseManagerDetail)}/> */}
                  <PrivateRoute
                    path="/wlf/Programs"
                    exact
                    component={waitFor(SanctionsProgram)}
                    objectId="WLF.Program"
                  />
                  <PrivateRoute
                    path="/wlf/Programs/:id"
                    exact
                    component={waitFor(SanctionsProgramDetail)}
                    objectId="WLF.Program.Details"
                  />
                  <PrivateRoute
                    path="/wlf/Whitelists"
                    exact
                    component={waitFor(WhiteList)}
                    objectId="WLF.Whitelist"
                  />
                  <PrivateRoute
                    path="/wlf/Whitelists/:id"
                    exact
                    component={waitFor(WhiteListDetail)}
                    objectId="WLF.Whitelist.Details"
                  />
                  <PrivateRoute
                    path="/wlf/StopSkipWord"
                    exact
                    component={waitFor(StopSkipWordList)}
                    objectId="WLF.StopSkipWord"
                  />
                  <PrivateRoute
                    path="/wlf/StopSkipWord/:id"
                    exact
                    component={waitFor(StopSkipWordDetail)}
                    objectId="WLF.StopSkipWord.Details"
                  />
                  {/* <PrivateRoute path="/wlf/module-setting" component={waitFor(ModuleSetting)}/> */}
                  {/* <PrivateRoute path="/wlf/form-extended" component={waitFor(FormExtended)}/> */}
                  {/* END WATCHLIST FILTERING */}
                  {/* Party Manager */}
                  {/* <PrivateRoute
                    path="/party/profile"
                    exact
                    component={waitFor(PartyProfile)}
                  />
                  <PrivateRoute
                    path="/party/profile/:id"
                    exact
                    component={waitFor(PartyProfileDetail)}
                  />

                  <PrivateRoute
                    path="/party/role"
                    exact
                    component={waitFor(PartyRole)}
                  />
                  <PrivateRoute
                    path="/party/role/:id"
                    exact
                    component={waitFor(PartyRoleDetail)}
                  />

                  <PrivateRoute
                    path="/party/individual"
                    exact
                    component={waitFor(PartyIndividual)}
                  />
                  <PrivateRoute
                    path="/party/individual/:id"
                    exact
                    component={waitFor(PartyIndividualDetail)}
                  />

                  <PrivateRoute
                    path="/party/organization"
                    exact
                    component={waitFor(PartyOrganization)}
                  />
                  <PrivateRoute
                    path="/party/organization/:id"
                    exact
                    component={waitFor(PartyOrganizationDetail)}
                  />

                  <PrivateRoute
                    path="/product/profile"
                    exact
                    component={waitFor(ProductProfile)}
                  />
                  <PrivateRoute
                    path="/product/profile/:id"
                    exact
                    component={waitFor(ProductProfileDetail)}
                  />
                  <PrivateRoute
                    path="/product/category"
                    exact
                    component={waitFor(ProductCategory)}
                  />
                  <PrivateRoute
                    path="/product/category/:id"
                    exact
                    component={waitFor(ProductCategoryDetail)}
                  />
                  <PrivateRoute
                    path="/product/manager"
                    exact
                    component={waitFor(ProductManager)}
                  />
                  <PrivateRoute
                    path="/product/manager/:id"
                    exact
                    component={waitFor(ProductManagerDetail)}
                  /> */}
                  {/* START KNOW YOUR PARTY */}
                  <PrivateRoute
                    path="/kyp/Dashboard"
                    exact
                    component={waitFor(KypDashboard)}
                    objectId="KYP.KYPDashboard"
                  />
                  <PrivateRoute
                    path="/kyp/Alerts"
                    exact
                    component={waitFor(KYPAlert)}
                    objectId="KYP.KYPAlert"
                  />
                  <PrivateRoute
                    path="/kyp/Alerts/:id"
                    exact
                    component={waitFor(KYPAlertDetail)}
                    objectId="KYP.KYPAlert.Details"
                  />
                  <PrivateRoute
                    path="/kyp/Profiles"
                    exact
                    component={waitFor(KypProfile)}
                    objectId="KYP.PartyProfile"
                  />
                  <PrivateRoute
                    path="/kyp/Profiles/:id"
                    exact
                    component={waitFor(KypProfileDetail)}
                    objectId="KYP.PartyProfile.Details"
                  />
                  <PrivateRoute
                    path="/kyp/Roles"
                    exact
                    component={waitFor(KypRole)}
                    objectId="KYP.PartyRole"
                  />
                  <PrivateRoute
                    path="/kyp/Roles/:id"
                    exact
                    component={waitFor(KypRoleDetail)}
                    objectId="KYP.PartyRole.Details"
                  />
                  {/* <PrivateRoute
                    path="/kyp/Acceptances"
                    exact
                    component={waitFor(KypAcceptancesList)}
                    objectId="KYP.Acceptances"
                  />
                  <PrivateRoute
                    path="/kyp/Acceptances/:id"
                    exact
                    component={waitFor(KypAcceptanceDetail)}
                    objectId="KYP.Acceptances.Details"
                  />

                  <PrivateRoute
                    path="/kyp/AcceptanceSegments"
                    exact
                    component={waitFor(KypAcceptanceSegments)}
                    objectId="KYP.AcceptanceSegments"
                  />
                  <PrivateRoute
                    path="/kyp/AcceptanceSegments/:id"
                    exact
                    component={waitFor(KypAcceptanceSegmentsDetail)}
                    objectId="KYP.AcceptanceSegments.Details"
                  /> */}
                  <PrivateRoute
                    path="/kyp/Risks"
                    exact
                    component={waitFor(KypRiskFactor)}
                    objectId="KYP.Risk"
                  />
                  <PrivateRoute
                    path="/kyp/Risks/:id"
                    exact
                    component={waitFor(KypRiskFactorDetail)}
                    objectId="KYP.Risk.Details"
                  />
                  <PrivateRoute
                    path="/kyp/RiskSegments"
                    exact
                    component={waitFor(KypRiskScoringDefine)}
                    objectId="KYP.RiskSegment"
                  />
                  <PrivateRoute
                    path="/kyp/RiskSegments/:id"
                    exact
                    component={waitFor(KypRiskScoreDefineDetail)}
                    objectId="KYP.RiskSegment.Details"
                  />
                  {/* <PrivateRoute path="/kyp/risk-assessment" exact component={waitFor(KypRiskAssessment)}/>
                                <PrivateRoute path="/kyp/risk-assessment/:id" exact component={waitFor(KypRiskAssessmentDetail)}/> */}
                  <PrivateRoute
                    path="/kyp/Individuals"
                    exact
                    component={waitFor(KypIndividual)}
                    objectId="KYP.Individual"
                  />
                  <PrivateRoute
                    path="/kyp/Individuals/:id"
                    exact
                    component={waitFor(KypIndividualDetail)}
                    objectId="KYP.Individual.Details"
                  />
                  <PrivateRoute
                    path="/kyp/Organizations"
                    exact
                    component={waitFor(KypOrganization)}
                    objectId="KYP.Organization"
                  />
                  <PrivateRoute
                    path="/kyp/Organizations/:id"
                    exact
                    component={waitFor(KypOrganizationDetail)}
                    objectId="KYP.Organization.Details"
                  />

                  <PrivateRoute
                    path="/kyp/Policies"
                    exact
                    component={waitFor(AMLPolicy)}
                    objectId="KYP.PolicySetup"
                  />

                  <PrivateRoute
                    path="/kyp/Settings"
                    exact
                    component={waitFor(KYPModuleSetting)}
                    objectId="KYP.KYPModuleSetting"
                  />

                  <PrivateRoute
                    path="/kyp/Reassessments"
                    exact
                    component={waitFor(KYPReassessment)}
                    objectId="KYP.Reassessment"
                  />

                  {/* END KNOW YOUR PARTY */}
                  {/* <PrivateRoute path="/kyp/module-setting" component={waitFor(KypModuleSetting)}/> */}
                  {/* START TRANSACTION MONIROTING - TMS */}
                  <PrivateRoute
                    path="/tm/Dashboard"
                    exact
                    component={waitFor(TmsDashboard)}
                    objectId="TM.TMSDashboard"
                  />
                  <PrivateRoute
                    path="/tm/Monitoring"
                    exact
                    component={waitFor(AlertMonitoring)}
                    objectId="TM.AlertMonitoring"
                  />
                  <PrivateRoute
                    path="/tm/Monitoring/:id"
                    exact
                    component={waitFor(AlertMonitoringDetail)}
                    objectId="TM.AlertMonitoring.Details"
                  />
                  <PrivateRoute
                    path="/tm/Investigations"
                    exact
                    // component={waitFor(tmsAlert2)}
                    component={waitFor(tmsInvestigation)}
                    objectId="TM.Investigation"
                  />
                  <PrivateRoute
                    path="/tm/Investigations/:id"
                    exact
                    //component={waitFor(tmsAlertDetail2)}
                    component={waitFor(tmsInvestigationDetail)}
                    objectId="TM.Investigation.Details"
                  />
                  <PrivateRoute
                    path="/tm/ScenarioAnalysis"
                    exact
                    component={waitFor(ScenarioAnalysis)}
                    objectId="TM.ScenarioAnalysis"
                  />
                  <PrivateRoute
                    path="/tm/ScenarioAnalysis/:id"
                    exact
                    component={waitFor(ScenarioAnalysisDetail)}
                    objectId="TM.ScenarioAnalysis.Details"
                  />
                  <PrivateRoute
                    path="/tm/CustomerAnalysis"
                    exact
                    component={waitFor(CustomerAnalysis)}
                    objectId="TM.CustomerAnalysis"
                  />
                  <PrivateRoute
                    path="/tm/CustomerAnalysis/:id"
                    exact
                    component={waitFor(CustomerAnalysisDetail)}
                    objectId="TM.CustomerAnalysis.Details"
                  />
                  <PrivateRoute
                    path="/tm/AMStaffAnalysis"
                    exact
                    component={waitFor(AmStaffAnalysis)}
                    objectId="TM.AMStaffAnalysis"
                  />
                  <PrivateRoute
                    path="/tm/AMStaffAnalysis/:id"
                    exact
                    component={waitFor(AmStaffAnalysisDetail)}
                    objectId="TM.AMStaffAnalysis.Details"
                  />
                  <PrivateRoute
                    path="/tm/CountryLinkAnalysis"
                    exact
                    component={waitFor(CountryAnalysis)}
                    objectId="TM.CountryLinkAnalysis"
                  />
                  <PrivateRoute
                    path="/tm/CountryLinkAnalysis/:id"
                    exact
                    component={waitFor(CountryAnalysisDetail)}
                    objectId="TM.CountryLinkAnalysis.Details"
                  />
                  <PrivateRoute
                    path="/tm/Scenarios"
                    exact
                    component={waitFor(ScenarioSetup)}
                    objectId="TM.Scenario"
                  />
                  <PrivateRoute
                    path="/tm/Scenarios/:id"
                    exact
                    component={waitFor(ScenarioSetupDetail)}
                    objectId="TM.Scenario.Details"
                  />
                  <PrivateRoute
                    path="/tm/ModuleSetting"
                    exact
                    component={waitFor(ModuleSetting)}
                    objectId="TM.ModuleSetting"
                  />
                  <PrivateRoute
                    path="/tm/LinkAnalysis"
                    exact
                    component={waitFor(LinkAnalysis)}
                    objectId="TM.LinkAnalysis"
                  />
                  {/* END TRANSACTION MONIROTING - TMS */}
                  {/* START STR MANAGEMENT - STR */}
                  <PrivateRoute
                    path="/str/STRManager"
                    exact
                    component={waitFor(STRManager)}
                    objectId="STR.STRManager"
                  />
                  <PrivateRoute
                    path="/str/STRManager/:id"
                    exact
                    component={waitFor(STRManagerDetail)}
                    objectId="STR.STRManager.Details"
                  />
                  {/* <PrivateRoute
                    path="/str/CaseProcess"
                    exact
                    component={waitFor(CaseProcess)}
                    objectId="STR.CaseProcess"
                  /> */}
                  {/* <PrivateRoute
                    path="/str/CaseProcess/:id"
                    exact
                    component={waitFor(CaseProcessDetail)}
                    objectId="STR.CaseProcess.Details"
                  /> */}
                  <PrivateRoute
                    path="/str/CaseManagement"
                    exact
                    component={waitFor(CaseManagement)}
                    objectId="STR.CaseManagement"
                  />
                  <PrivateRoute
                    path="/str/CaseManagement/:id"
                    exact
                    component={waitFor(CaseManagementDetail)}
                    objectId="STR.CaseManagement"
                  />
                  <PrivateRoute
                    path="/str/GroupAnalysis"
                    exact
                    component={waitFor(CustomerSearchingPage)}
                    objectId="STR.GroupAnalysis"
                  />
                  {/* END STR MANAGEMENT - STR */}
                  {/* START CASE MANAGEMENT  */}

                  <PrivateRoute
                    path="/ce/CaseManagement"
                    exact
                    component={waitFor(CaseManagement)}
                    objectId="CE.CaseManagement"
                  />
                  <PrivateRoute
                    path="/ce/CaseManagement/:id"
                    exact
                    component={waitFor(CaseManagementDetail)}
                    objectId="CE.CaseManagement.Details"
                  />
                  <PrivateRoute
                    path="/ce/CaseProcess"
                    exact
                    component={waitFor(CECaseProcess)}
                    objectId="CE.CaseProcess"
                  />

                  <PrivateRoute
                    path="/ce/CaseProcess/:id"
                    exact
                    component={waitFor(CECaseProcessDetail)}
                    objectId="CE.CaseProcess.Details"
                  />

                  <PrivateRoute
                    path="/ce/CaseWorkflow"
                    exact
                    component={waitFor(CaseWorkflow)}
                    objectId="CE.CaseWorkflow"
                  />
                  <PrivateRoute
                    path="/ce/CaseWorkflow/:id"
                    exact
                    component={waitFor(CaseWorkflowDetail)}
                    objectId="CE.CaseWorkflow.Details"
                  />
                  <PrivateRoute
                    path="/ce/CasePriority"
                    exact
                    component={waitFor(CasePriority)}
                    objectId="CE.CasePriority"
                  />
                  <PrivateRoute
                    path="/ce/CasePriority/:id"
                    exact
                    component={waitFor(CasePriorityDetail)}
                    objectId="CE.CasePriority.Details"
                  />
                  <PrivateRoute
                    path="/ce/CaseAlert"
                    exact
                    component={waitFor(CaseAlert)}
                    objectId="CE.CaseAlert"
                  />
                  <PrivateRoute
                    path="/ce/CaseAlert/:id"
                    exact
                    component={waitFor(CaseAlertDetail)}
                    objectId="CE.CaseAlert.Details"
                  />

                  {/* <PrivateRoute
                    path="/ce/CaseProcess/:id"
                    exact
                    component={waitFor(CaseProcessDetail)}
                    objectId="CE.CaseProcess.Details"
                  /> */}
                  {/* <PrivateRoute
                    path="/ce/CaseManagement"
                    exact
                    component={waitFor(CaseManagement)}
                    objectId="CE.CaseManagement"
                  />

                  <PrivateRoute
                    path="/ce/CaseManagement/:id"
                    exact
                    component={waitFor(CaseManagementDetail)}
                    objectId="CE.CaseManagement"
                  /> */}
                  {/* END CASE MANAGEMENT  */}
                  {/* CustomerSearchingPage */}
                  {/* START DATA VISUALIZATION */}
                  <PrivateRoute
                    path="/dv/Workspace"
                    exact
                    component={waitFor(WorkspaceMainPage)}
                    objectId="DV.InvestigationList"
                  />
                  <PrivateRoute
                    path="/dv/CustomerSearching"
                    exact
                    component={waitFor(CustemerSearchingMainPage)}
                    objectId="DV.CustomerSearching"
                  />
                  <PrivateRoute
                    path="/dv/CustomerSearching/:id"
                    exact
                    component={waitFor(CustomerSearchingPage)}
                    objectId="DV.CustomerSearching.Details"
                  />
                  <PrivateRoute
                    path="/dv/GroupSearching"
                    exact
                    component={waitFor(GroupSearchingMainPage)}
                    objectId="DV.GroupSearching"
                  />
                  <PrivateRoute
                    path="/dv/GroupSearching/:id"
                    exact
                    component={waitFor(GrouprSearchingPage)}
                    objectId="DV.GroupSearching"
                  />
                  {/* END CASE MANAGEMENT  */}
                  {/* EmbededNeo4jBrowser */}
                  {/* START GRAPH BROWSER */}
                  <PrivateRoute
                    path="/dv/Investigation"
                    exact
                    component={waitFor(InvestigationList)}
                    objectId="DV.InvestigationList"
                  />
                  <PrivateRoute
                    path="/dv/Investigation/:id"
                    exact
                    component={waitFor(InvestigationDetail)}
                    objectId="DV.InvestigationList.Details"
                  />
                  {/* START GRAPH BROWSER */}
                  <PrivateRoute
                    path="/dv/GraphBrowser"
                    exact
                    component={waitFor(EmbededNeo4jBrowser)}
                    objectId="DV.GraphBrowser"
                  />
                  {/* END CASE MANAGEMENT  */}
                  {/* START MASTER SETUP */}
                  {/* <PrivateRoute
                    path="/ms/Dashboard"
                    exact
                    component={waitFor(KypDashboard)}
                    objectId="MS.MSDashboard"
                  /> */}

                  <PrivateRoute
                    path="/ms/CompanyInformations"
                    exact
                    component={waitFor(MsCompanyInformations)}
                    objectId="MS.CompanyInformation"
                  />
                  <PrivateRoute
                    path="/ms/DualControlSetting"
                    exact
                    component={waitFor(MsDualControls)}
                    objectId="MS.DualControlSetting"
                  />
                  <PrivateRoute
                    path="/ms/DualControlSetting/:id"
                    exact
                    component={waitFor(MsDualControlsDetail)}
                    objectId="MS.DualControlSetting.Details"
                  />
                  <PrivateRoute
                    path="/ms/ExportDataSetting"
                    exact
                    component={waitFor(ExportDataSetting)}
                    objectId="MS.ExportDataSetting"
                  />
                  <PrivateRoute
                    path="/ms/BatchJobs"
                    exact
                    component={waitFor(BatchJobManagement)}
                    objectId="MS.BatchJobs"
                  />
                  <PrivateRoute
                    path="/ms/BatchJobs/:id"
                    exact
                    component={waitFor(BatchJobDetail)}
                    objectId="MS.BatchJobs.Details"
                  />
                  <PrivateRoute
                    path="/ms/UserRoles"
                    exact
                    component={waitFor(MsUserRole)}
                    objectId="MS.UserRole"
                  />
                  <PrivateRoute
                    path="/ms/UserRoles/:id"
                    exact
                    component={waitFor(MsUserRoleDetail)}
                    objectId="MS.UserRole.Details"
                  />
                  <PrivateRoute
                    path="/ms/Users"
                    exact
                    component={waitFor(MsUserManager)}
                    objectId="MS.User"
                  />
                  <PrivateRoute
                    path="/ms/Users/:id"
                    exact
                    component={waitFor(MsUserManagerDetail)}
                    objectId="MS.User.Details"
                  />
                  <PrivateRoute
                    path="/ms/SystemCodes"
                    exact
                    component={waitFor(MsSystemCode)}
                    objectId="MS.SystemCodes"
                  />
                  <PrivateRoute
                    path="/ms/SystemCodes/:id"
                    exact
                    component={waitFor(MsSystemCodeDetail)}
                    objectId="MS.SystemCodes.Details"
                  />
                  <PrivateRoute
                    path="/ms/NotificationTemplate"
                    exact
                    component={waitFor(MsTemplate)}
                    objectId="MS.NotificationTemplate"
                  />
                  <PrivateRoute
                    path="/ms/NotificationTemplate/:id"
                    exact
                    component={waitFor(MsTemplateDetail)}
                    objectId="MS.NotificationTemplate.Details"
                  />
                  <PrivateRoute
                    path="/ms/SystemCodes"
                    exact
                    component={waitFor(MsSystemCode)}
                    objectId="MS.SystemCodes"
                  />
                  <PrivateRoute
                    path="/ms/SystemCodes/:id"
                    exact
                    component={waitFor(MsSystemCodeDetail)}
                    objectId="MS.SystemCodes.Details"
                  />

                  <PrivateRoute
                    path="/ms/AuditLogs"
                    exact
                    component={waitFor(AuditLogsDetail)}
                    objectId="MS.AuditLog"
                  />
                  {/* END MASTER SETUP */}
                  {/* START REPORT MANAGEMENT  */}
                  <PrivateRoute
                    path="/rp/STReport"
                    exact
                    component={waitFor(STReport)}
                    objectId="Report.Alert--STReport"
                  />
                  <PrivateRoute
                    path="/rp/LogUsageOfDataVisualization"
                    exact
                    component={waitFor(AccessDVReport)}
                    objectId="Report.Administration--LogUsageOfDataVisualization"
                  />
                  <PrivateRoute
                    path="/rp/LogUsageOfReport"
                    exact
                    component={waitFor(AccessReport)}
                    objectId="Report.Administration--LogUsageOfReport"
                  />
                  <PrivateRoute
                    path="/rp/FIReport"
                    exact
                    component={waitFor(FIReport)}
                    objectId="Report.Customer--FinancialInstitution"
                  />
                  <PrivateRoute
                    path="/rp/CustomerDetail"
                    exact
                    component={waitFor(CustomerDetailReport)}
                    objectId="Report.Customer--CustomerDetail"
                  />
                  <PrivateRoute
                    path="/rp/CustomerTransaction"
                    exact
                    component={waitFor(CustomerTransactionReport)}
                    objectId="Report.Transaction--CustomerTransaction"
                  />
                  <PrivateRoute
                    path="/rp/SummaryCustomer"
                    exact
                    component={waitFor(SummaryCustomerReport)}
                    objectId="Report.Customer--SummaryCustomer"
                  />
                  <PrivateRoute
                    path="/rp/SummaryCustomerTransaction"
                    exact
                    component={waitFor(SummaryCustomerTransactionReport)}
                    objectId="Report.Transaction--SummaryCustomerTransaction"
                  />
                  <PrivateRoute
                    path="/rp/TopCustomer"
                    exact
                    component={waitFor(SummaryTopCustomerReport)}
                    objectId="Report.Transaction--TopCustomer"
                  />
                  <PrivateRoute
                    path="/rp/StatisticCustomerTransaction"
                    exact
                    component={waitFor(StatisticCustomerTransactionReport)}
                    objectId="Report.Transaction--StatisticCustomerTransaction"
                  />
                  <PrivateRoute
                    path="/rp/SubmitedIncompleteReport"
                    exact
                    component={waitFor(BankReportMissing)}
                    objectId="Report.Transaction--SubmitedIncompleteReports"
                  />
                  <PrivateRoute
                    path="/rp/SummaryByAlert"
                    exact
                    component={waitFor(SummaryByAlertReport)}
                    objectId="Report.Alert--SummaryByAlert"
                  />
                  <PrivateRoute
                    path="/rp/SummaryByCustomer"
                    exact
                    component={waitFor(SummaryByCustomerReport)}
                    objectId="Report.Alert--SummaryByCustomer"
                  />
                  {/* END REPORT MANAGEMENT */}
                  <PrivateRoute
                    path="/"
                    exact
                    component={waitFor(KypDashboard)}
                  />
                  <PrivateRoute
                    path="/404"
                    exact
                    component={waitFor(NotFound)}
                    exclude
                  />
                  {/* START ALERT MANAGEMENT  */}

                  <PrivateRoute
                    path="/at/AlertScenarioSetup"
                    exact
                    component={waitFor(AlertScenarioSetup)}
                    objectId="AT.AlertScenarioSetup"
                  />
                  <PrivateRoute
                    path="/at/AlertScenarioSetup/:id"
                    exact
                    component={waitFor(AlertScenarioSetupDetail)}
                    objectId="AT.AlertScenarioSetup.Details"
                  />
                  <PrivateRoute
                    path="/at/Whitelist"
                    exact
                    component={waitFor(AlertWhiteList)}
                    objectId="AT.AlertWhitelist"
                  />
                  <PrivateRoute
                    path="/at/Whitelist/:id"
                    exact
                    component={waitFor(AlertWhiteListDetail)}
                    objectId="AT.AlertWhitelist.Details"
                  />
                  <PrivateRoute
                    path="/at/Results"
                    exact
                    component={waitFor(AlertResults)}
                    objectId="AT.AlertResult"
                  />
                  <PrivateRoute
                    path="/at/Results/:id"
                    exact
                    component={waitFor(AlertResultDetail)}
                    objectId="AT.AlertResult.Details"
                  />
                  <PrivateRoute
                    path="/at/CustomerResults"
                    exact
                    component={waitFor(AlertResultsCustomer)}
                    objectId="AT.AlertResultByCust"
                  />
                  <PrivateRoute
                    path="/at/CustomerResults/:id"
                    exact
                    component={waitFor(AlertResultCustomerDetail)}
                    objectId="AT.AlertResultByCust.Details"
                  />
                  <PrivateRoute
                    path="/at/NCCTTaxHaven"
                    exact
                    component={waitFor(AlertNCCTTaxHaven)}
                    objectId="AT.NCCTTaxHaven"
                  />
                  <PrivateRoute
                    path="/at/STRManual"
                    exact
                    component={waitFor(AlertSTRManual)}
                    objectId="AT.AlertWhitelist"
                  />
                  <PrivateRoute
                    path="/at/STRManual/:id"
                    exact
                    component={waitFor(AlertSTRManualDetail)}
                    objectId="AT.AlertWhitelist.Details"
                  />
                  <PrivateRoute
                    path="/at/RequestFI"
                    exact
                    component={waitFor(AlertRequestFI)}
                    objectId="AT.AlertRequestFI"
                  />
                  <PrivateRoute
                    path="/at/RequestFI/:id"
                    exact
                    component={waitFor(AlertRequestFIDetail)}
                    objectId="AT.AlertRequestFI.Details"
                  />
                  <PrivateRoute
                    path="/at/Approval"
                    exact
                    component={waitFor(DelegateTask)}
                    objectId="AT.AlertApproval"
                  />
                  <PrivateRoute
                    path="/at/Process"
                    exact
                    component={waitFor(AlertProgress)}
                    objectId="AT.Process"
                  />
                  <PrivateRoute
                    path="/at/Assistant"
                    exact
                    component={waitFor(AlertAssistant)}
                    objectId="AT.Assistant"
                  />
                  {/* END ALERT SETUP */}
                  <PrivateRoute
                    path="/dfd/live-transaction"
                    exact
                    component={waitFor(FraudDetection)}
                    objectId="DFD.LiveTransaction"
                  />

                  <PrivateRoute
                    path="/db/CustomerInfo"
                    exact
                    component={waitFor(CustomerInfo)}
                    objectId="Validation.CustomerInfo"
                  />
                  <PrivateRoute
                    path="/db/ValidationLog"
                    exact
                    component={waitFor(ValidationLog)}
                    objectId="Validation.ValidationLog"
                  />
                  {/* <Redirect to="/" /> */}
                  <Redirect to="/404" />
                  {/* <Redirect from='/' to='/tm/LinkAnalysis' /> */}
                </Switch>
              </Suspense>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Base>
    );
  }
};

export default withRouter(Routes);
