import React, { useCallback } from "react";
import { TabPane } from "reactstrap";

const NavContent = (props) => {
  const { items } = props;

  const renderItem = useCallback(() => {
    return items.map((item, idx) => (
      <TabPane key={item.id + idx} tabId={item.id}>
        {item.comp}
      </TabPane>
    ));
  }, [items]);

  return (
    <div className="tab-content bg-white p-0">
      <div className="p-3">{renderItem()}</div>
    </div>
    // <TabContent activeTab={activeTab} className="bg-white p-0">
    //   {renderItem()}
    // </TabContent>
  );
};

export default NavContent;
