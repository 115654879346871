import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

const CustomSelect = (props) => {
  const onChangeHandler = (name, e) => {
    props.onChange({ name, value: e.value });
  };
  return (
    <Select
      {...props.inputProps}
      name={props.name}
      // {...inputProp}
      closeMenuOnSelect={!props.isMulti}
      defaultValue={props.defaultValue}
      onChange={(e) => onChangeHandler(props.name, e)}
      options={props.options}
      isDisabled={props.isDisabled}
    />
  );
};

CustomSelect.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
    .isRequired,
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

export default CustomSelect;
