export const SelectType = {
  BASE_RULES: 55550,
  MODULES: 66660,
  DUAL_CONTROL_STATUS: 77770,
  SYSTEM_CODES: 88880,
  ROLE_APPLIED: 99990,
  COMPANIES: 99991,
  MS_COMPANIES: 99992,
  USERS: 99993,
  GENDER: 100001,
  DOMESTIC_OR_FOREIGNER: 100002,
  IDENTIFICATION_DOC: 100003,
  PROVINCE: 100004,
  NATIONALITY: 100005,
  INDUSTRY: 100006,
  OCCUPACY: 100007,
  WORKING_TITLE: 100008,
  ADDR_TYPE: 100009,
  CONTACT_TYPE_IND: 100010,
  CONTACT_TYPE_ORG: 100011,
  LEAD_SRC_IND: 100012,
  SEARCH_ENGINE: 100013,
  SOCIAL_NETWORK: 100014,
  HOBBY: 100015,
  TRANS_CHANNEL: 100016,
  RELIGION: 100017,
  DATA_TYPE: 100018,
  FILE_TYPE: 100019,
  DOC_CATEGORY: 100020,
  DOC_SHARING: 100021,
  QUESTION_CATEGORY: 100022,
  RISK_LEVEL: 100023,
  AML_POLICY: 100024,
  NEXT_ACTION: 100025,
  RISK_CATEGORY: 100026,
  WATCHLIST_CATEGORY: 100027,
  WATCHLIST_FILE_TYPE: 100028,
  WATCHLIST_CHANNEL_TYPE: 100029,
  SOURCE_SYSTEM: 100030,
  WATCHLIST_TRANSMISSION_TYPE: 100031,
  // CONNECTION_TYPE: 100032,
  CONNECTION_TYPE: "CONNTYPE",
  WATCHLIST_FORMAT_TYPE: 100033,
  WATCHLIST_CHECK_TYPE: 100034,
  RULE_CATEGORY: 35,
  TMS_DATA_TYPE: 36,
  TMS_FREQUENCY: 37,
  TMS_RISK_LEVEL: 38,
  TMS_SUSPICIOUS_CATEGORY: 39,
  STOCK_EXCHANGE_CODE: 100040,
  ORGANIZATION_TYPE: 100041,
  ORGANIZATION_BIZ_TYPE: 100042,
  CASE_RESOLVE_CATEGORY: 66,
  WHITELIST_ADD_REASONS: 67,
  CASE_STATUS: 68,
  RISK_FACTOR_CATEGORY: 100069,
  PRODUCT_SERVICE: 70,
  RISK_TYPE: 100026,
  RELATED_PERSONS: 72,
  RELATED_ORGANIZATIONS: 73,
  ACCEPTANCE_STATE: 100071,
  QUESTION_TYPE: 100022,
  ISSUER_CODE: 100074,
  RELATED_CODE: 100074,
  COMPANY_TYPE: 100000,
  WARD: 100079,
  LEADER_CODE: "MALANHDAO",
  ANALYS_RESULT_CATE: "ANALYSRESULTCATE",
  AL_PERIOD_TYPE: "AL.PERIOD.TYPE",
  AML_DETECTEVENT: "AML.DETECTEVENT",
  DETECT_MODEL_TYPE: "AML.DETECTMODEL",
  CASE_SLA_TYPE: "CMS.SLA",
  CASE_TASK_JOB: "CMS.TASKJOB",
  CASE_TASK_DOC: "CMS.TASKDOC",
  AML_SOURCEMODULE: "AML.SOURCEMODULE",
  CMS_SLAMEASUREMENT: "CMS.SLAMEASUREMENT",
  CMS_SLAPERIOD: "CMS.SLAPERIOD",
  CMS_CASESTATUS: "CMS.CASESTATUS",
  CMS_CASEPRIORITY: "CMS.CASEPRIORITY",
  CMS_TASKACTION: "CMS.TASKACTION",
  AL_RISK_L: "AL.RISK.L",
  AML_BUSINESSLINE: "AML.BUSINESSLINE",
  KYP_RISKLEVEL: "KYP.RISKLEVEL",
  KYP_SCCCATEGORY: "KYP.SCCCATEGORY",
  KYP_AMLPOLICYCRITERIA: "KYP.AMLPOLICYCRITERIA",
  KYP_REASSESSMENTCYCLE: "KYP.REASSESSMENTCYCLE",
  KYP_RISKSTATE: "KYP.RISKSTATE",
  TMS_ACCOUNTTYPE: "TMS.ACCOUNTTYPE",
  TMS_ACCOUNTSTATUS: "TMS.ACCOUNTSTATUS",
  DATA_VALIDATION_TAG: "DATA_VALIDATION_TAG",
};
