import {
  DB_RESET_CUST_INFO,
  DB_FETCH_CUST_INFO_LIST_START,
  DB_FETCH_CUST_INFO_LIST_SUCCESS,
  DB_FETCH_CUST_INFO_LIST_FAILED,
  DB_FETCH_CUST_INFO_START,
  DB_FETCH_CUST_INFO_SUCCESS,
  DB_FETCH_CUST_INFO_FAILED,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  custInfos: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoadingList: false,
  isLoadingDetail: false,
  custInfo: {},
};

const dbCustInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case DB_RESET_CUST_INFO:
      return updatedObject(state, {
        isLoadingDetail: false,
        custInfos: [],
        custInfo: {},
      });

    case DB_FETCH_CUST_INFO_LIST_START:
      return updatedObject(state, {
        isLoadingList: true,
        paging: initialState.paging,
        custInfos: initialState.custInfos,
      });

    case DB_FETCH_CUST_INFO_LIST_SUCCESS:
      const custInfos = { ...action.payload };
      const newPaging = {
        activePage: custInfos.number + 1,
        itemsCountPerPage: custInfos.size,
        totalItemsCount: custInfos.totalElements,
        dataSize: custInfos.content ? custInfos.content.length : 0,
      };
      return updatedObject(state, {
        custInfos: action.payload.content || [],
        paging: newPaging,
        isLoadingList: false,
      });

    case DB_FETCH_CUST_INFO_LIST_FAILED:
      return updatedObject(state, {
        isLoadingList: false,
        paging: initialState.paging,
        custInfos: initialState.custInfos,
      });

    case DB_FETCH_CUST_INFO_START:
      return updatedObject(state, {
        isLoadingDetail: true,
        custInfo: initialState.custInfo,
      });

    case DB_FETCH_CUST_INFO_SUCCESS:
      const custInfo = { ...action.payload };
      return updatedObject(state, {
        custInfo: custInfo,
        isLoadingDetail: false,
      });
    case DB_FETCH_CUST_INFO_FAILED:
      return updatedObject(state, {
        isLoadingDetail: false,
        custInfo: initialState.custInfo,
      });

    default:
      return state;
  }
};
export default dbCustInfoReducer;
