import React from "react";
import PropTypes from "prop-types";
import { Input } from "reactstrap";

const Radio = (props) => (
  <div className="c-radio">
    <label>
      <Input
        type="radio"
        name={props.name}
        defaultValue={props.value}
        defaultChecked={props.checked}
      />
      <span className="fa fa-circle"></span>
      {props.label}
    </label>
  </div>
);

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  checked: PropTypes.bool,
  label: PropTypes.string,
};

export default Radio;
