import React from "react";
import FormModal from "../FormModal";
// import { FormGroup } from "reactstrap";

import TitleText from "../TitleText";
import DynamicForm from "../DynamicForm2";

const QuestionListModal = (props) => {
  const {
    isModalOpen,
    toggleModal,
    modalTitle,
    onSubmit,
    onChangeInput,
    items,
    onSelectHandler: onSelectInput,
    hasError,
  } = props;

  const onChangeInputHandler = (e, acceptanceId) => {
    onChangeInput(e, acceptanceId);
  };

  const onSelectHandler = (e, acceptanceId) => {
    onSelectInput(e, acceptanceId);
  };

  return (
    <FormModal
      isOpenedModal={isModalOpen}
      clicked={toggleModal}
      title={modalTitle || "Acceptance Question List "}
      onSubmit={onSubmit}
    >
      <form name="acceptanceForm" id="acceptanceForm">
        {/* {items.map(
          (_item) => */}
        {items?.filter((item, idx) => item.idenInfo)?.length > 0 && (
          <div>
            <TitleText>Thông tin định danh</TitleText>
            <DynamicForm
              formName="acceptanceForm"
              style={{ paddingLeft: "15px", paddingRight: "15px" }}
              items={items?.filter((item, idx) => item.idenInfo)}
              onInputChangeHandler={(e) => onChangeInputHandler(e)}
              onSelectHandler={(e) => onSelectHandler(e)}
              hasError={hasError}
            />
          </div>
        )}

        {items?.filter((item, idx) => !item.idenInfo)?.length > 0 && (
          <div>
            <TitleText>Thông tin khác</TitleText>
            <DynamicForm
              formName="acceptanceForm"
              style={{ paddingLeft: "15px", paddingRight: "15px" }}
              items={items?.filter((item, idx) => !item.idenInfo)}
              onInputChangeHandler={(e) => onChangeInputHandler(e)}
              onSelectHandler={(e) => onSelectHandler(e)}
              hasError={hasError}
            />
          </div>
        )}
      </form>
    </FormModal>
  );
};

export default QuestionListModal;
